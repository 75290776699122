







































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    Modal,
    TextInput,
    Button,
  },
})
export default class ModalConfirmation extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: '' }) private statusID!: string
  @Prop({ default: '' }) private type!: string
  @Prop({ default: 0, required: false }) private amount!: number

  private formatCurrency(input: number) {
    if (input) {
      return `Rp ${Utils.currencyDigit(input)}`
    } else {
      return `Rp ${Utils.currencyDigit(0)}`
    }
  }
}
