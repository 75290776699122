export enum EnumOldClaimStatus {
  PENDING = 'PENDING',
  ONPROGRESS = 'ONPROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  DEFAULT = 'DEFAULT' // for manipulate
}

export enum EnumNewClaimStatus {
  NEW = 'NEW',
  WORKING = 'WORKING',
  APPROVE = 'APPROVE',
  PENDING = 'PENDING',
  PAID = 'PAID',
  REJECT = 'REJECT',
  DEFAULT = 'DEFAULT' // For manipulate
}


export const oldClaimStatuses = [
  {
    status: EnumOldClaimStatus.PENDING,
    actor: 'CUSTOMER',
    label: "Pengajuan",
    position: 'past',
  },
  {
    status: EnumOldClaimStatus.ONPROGRESS,
    actor: 'CNM',
    label: 'Pengecekan',
    position: 'current',
  },
  {
    status: EnumNewClaimStatus.DEFAULT,
    actor: '',
    label: 'Penyelesaian',
    position: 'future',
  },
  {
    status: EnumOldClaimStatus.APPROVED,
    actor: 'FINANCE',
    label: 'Disetujui',
    position: 'future',
  },
  {
    status: EnumOldClaimStatus.REJECTED,
    actor: '',
    label: 'Ditolak',
    position: 'future',
  },
]

export const newClaimStatuses = [
  {
    status: EnumNewClaimStatus.NEW,
    actor: 'SF',
    label: "Pengajuan",
    position: 'past',
  },
  {
    status: EnumNewClaimStatus.WORKING,
    actor: 'SF',
    label: 'Pengecekan',
    position: 'current',
  },
  {
    status: EnumNewClaimStatus.APPROVE,
    actor: 'SF',
    label: 'Pengecekan',
    position: 'future',
  },
  {
    status: EnumNewClaimStatus.PENDING,
    actor: 'SF',
    label: 'Pengecekan',
    position: 'future',
  },
  {
    status: EnumNewClaimStatus.DEFAULT,
    actor: '',
    label: 'Penyelesaian',
    position: 'future',
  },
  {
    status: EnumNewClaimStatus.PAID,
    actor: '',
    label: 'Disetujui',
    position: 'future',
  },
  {
    status: EnumNewClaimStatus.REJECT,
    actor: '',
    label: 'Ditolak',
    position: 'future',
  },
]

