






















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import dayjsRelativeTime from 'dayjs/plugin/relativeTime'
import { Claim, ClaimHistory, PackageInfo, ShipmentInfo } from '@/domain/entities/Claim'
import controller from '@/app/ui/controllers/ClaimController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalRejected from '@/app/ui/views/Claim/components/Modals/ModalRejected.vue'
import ModalConfirmation from '@/app/ui/views/Claim/components/Modals/ModalConfirmation.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Stepper from '@/app/ui/components/Stepper/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import IconClipboard from '@/app/ui/assets/icon_clipboard.vue'
import IconProfile from '@/app/ui/assets/icon_profile.vue'
import IconWarningCircle from '@/app/ui/assets/icon_warning_circle.vue'
import {
  EnumNewClaimStatus,
  EnumOldClaimStatus,
  newClaimStatuses,
  oldClaimStatuses
} from '@/app/infrastructures/misc/Constants/claims'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'

dayjs.extend(dayjsRelativeTime)

interface ItemsStepper {
  label: string;
  position?: string;
  date?: string;
}

interface DateLabel {
  date: string;
  label?: string;
}

interface StatusesTypes {
  status: string;
  actor: string;
  label: string;
  position: string;
}

@Component({
  components: {
    Button,
    Modal,
    ModalRejected,
    ModalConfirmation,
    TextInput,
    LoadingOverlay,
    Stepper,
    Badge,
    IconClipboard,
    IconProfile,
    IconWarningCircle,
    CaretDownIcon
  },
})
export default class ClaimDetailPage extends Vue {
  isGuest = Utils.isGuest()
  isFinance = Utils.isFinance()
  isCNM = Utils.isCNM()
  isSuperAdmin = Utils.isSuperAdmin()
  controller = controller
  rejectConfirmationModal = false
  approveConfirmationModal = false
  successModal = false
  successModalMsg = {
    title: '',
    body: '',
  }
  form = {
    note: '',
    claimStatus: '',
  }
  EnumOldClaimStatus = EnumOldClaimStatus
  EnumNewClaimStatus = EnumNewClaimStatus
  isShowDetail = false

  created(): void {
    this.fetchDetail()
  }

  get claimDetail(): Claim {
    return controller.claimDetail
  }

  get indexNewClaim(): { statuses: StatusesTypes[], currentIndex: number } {
    const statuses = newClaimStatuses.filter((item) => {
      if (
        (
          item.status === EnumNewClaimStatus.WORKING &&
          [
            EnumNewClaimStatus.APPROVE,
            EnumNewClaimStatus.PENDING
          ].includes(<EnumNewClaimStatus>this.claimStatus.statusId)) ||
        (item.status === EnumNewClaimStatus.APPROVE && this.claimStatus.statusId !== item.status) ||
        (item.status === EnumNewClaimStatus.PENDING && this.claimStatus.statusId !== item.status) ||
        (item.status === EnumNewClaimStatus.REJECT && this.claimStatus.statusId !== item.status) ||
        (item.status === EnumNewClaimStatus.PAID && this.claimStatus.statusId !== item.status) ||
        (
          item.status === EnumNewClaimStatus.DEFAULT &&
          [
            EnumNewClaimStatus.PAID,
            EnumNewClaimStatus.REJECT
          ].includes(<EnumNewClaimStatus>this.claimStatus.statusId)
        )
      ) {
        return false
      } else {
        return true
      }
    })
    const currentIndex = statuses.findIndex((item) => item.status === this.claimStatus.statusId)
    return { statuses, currentIndex }
  }

  get indexOldClaim(): { statuses: StatusesTypes[], currentIndex: number } {
    const statuses = oldClaimStatuses.filter((item) => {
      if (
        (
          item.status === EnumOldClaimStatus.APPROVED &&
          this.claimStatus.statusId === EnumOldClaimStatus.REJECTED
        ) ||
        (item.status === EnumOldClaimStatus.REJECTED && this.claimStatus.statusId !== item.status) ||
        (item.status === EnumOldClaimStatus.APPROVED && this.claimStatus.statusId !== item.status) ||
        (
          item.status === EnumOldClaimStatus.DEFAULT &&
          [
            EnumOldClaimStatus.REJECTED,
            EnumOldClaimStatus.APPROVED
          ].includes(<EnumOldClaimStatus>this.claimStatus.statusId)
        )
      ) {
        return false
      } else {
        return true
      }
    })

    const currentIndex = statuses.findIndex((item) => item.status === this.claimStatus.statusId)
    return { statuses, currentIndex}
  }

  get claimStatusList(): ItemsStepper[] {
    let currentIndex = 0
    let statuses = []
    if (this.claimDetail.externalClaimId) {
      currentIndex = this.indexNewClaim.currentIndex
      statuses = this.indexNewClaim.statuses
    } else {
      currentIndex = this.indexOldClaim.currentIndex
      statuses = this.indexOldClaim.statuses
    }

    const statusList = statuses.map((item, idx) => {
      if (idx < currentIndex) {
        item.position = 'past'
      } else if (idx > currentIndex) {
        item.position = 'future'
      } else {
        item.position = 'current'
      }

      return {
        label: item.label,
        position: item.position,
      }
    })
    return statusList
  }

  get claimStatus(): ClaimHistory {
    return controller.claimDetail.claimHistories &&
      controller.claimDetail.claimHistories.length > 0
      ? controller.claimDetail.claimHistories[0]
      : new ClaimHistory()
  }

  get claimStatusId(): string {
    const { claimHistories } = controller.claimDetail
    if (claimHistories && claimHistories.length > 0) {
      if (
        claimHistories[0].statusId === EnumOldClaimStatus.PENDING ||
        claimHistories[0].statusId === EnumOldClaimStatus.ONPROGRESS ||
        claimHistories[0].statusId === EnumNewClaimStatus.NEW ||
        claimHistories[0].statusId === EnumNewClaimStatus.WORKING ||
        claimHistories[0].statusId === EnumNewClaimStatus.APPROVE
      ) {
        return "warning"
      } else if (
        claimHistories[0].statusId === EnumOldClaimStatus.APPROVED ||
        claimHistories[0].statusId === EnumNewClaimStatus.PAID
      ) {
        return "success"
      } else {
        return "error"
      }
    } else {
      return ""
    }
  }

  get claimStatusDescription(): string {
    return `${this.claimStatus.statusId} by ${this.claimStatus.actorName}`
  }

  get shipmentInfo(): ShipmentInfo {
    return (
      controller.claimDetail.shipmentDetail?.shipmentInfo || { createdAt: '' }
    )
  }

  get insuranceFee(): string {
    return this.shipmentInfo.insuranceFee
      ? this.formatCurrency(Number(this.shipmentInfo.insuranceFee))
      : 'No'
  }

  get invoiceDetails(): DateLabel[] {
    const data = controller.claimDetail.invoiceDetails
      ?.filter((inv) => inv.paymentMethod)
      ?.sort(
        (a, b) =>
          new Date(<string>b.finishedAt).getTime() -
          new Date(<string>a.finishedAt).getTime()
      ) || []

    const result = data.map(item => ({
      date: `${this.getDate(item.finishedAt)} ${this.getTime(item.finishedAt)}`,
      label: `${item.id}_${item.paymentMethod}`
    }))
    return result
  }

  get packageInfo(): PackageInfo {
    return controller.claimDetail.shipmentDetail?.packageInfo || {}
  }

  get shipmentHistories(): DateLabel[] {
    const data = controller.claimDetail.shipmentDetail?.packageHistories
      ?.concat(
        ...(controller.claimDetail.shipmentDetail?.shipmentHistories || [])
      )
      .sort(
        (a, b) =>
          new Date(<string>b.createdAt).getTime() -
          new Date(<string>a.createdAt).getTime()
      ) || []

    const result = data.map(item => ({
      date: `${this.getDate(item.createdAt)} ${this.getTime(item.createdAt)}`,
      label: item.statusDescription
    }))

    return result
  }

  get claimHistories(): ClaimHistory[] {
    return controller.claimDetail.claimHistories || [new ClaimHistory()]
  }

  get historyWorkLog(): ItemsStepper[] {
    const data = this.claimHistories.map(item => {
      const result = {
        date: `${this.getDate(item.createdAt)} ${this.getTime(item.createdAt)}`
      }
      return { ...result, label: `${item.statusId} by ${item.actorName}` }
    })
    return data
  }

  get shipmentOrderDate(): string {
    if (
      controller.claimDetail.packageId?.startsWith('11-') ||
      controller.claimDetail.packageId?.startsWith('10-')
    ) {
      return `${this.getDate(
        this.shipmentHistories[this.shipmentHistories.length - 1].date
      )} ${this.getTime(
        this.shipmentHistories[this.shipmentHistories.length - 1].date
      )}`
    } else {
      return `${this.getDate(this.shipmentInfo.createdAt)} ${this.getTime(
        this.shipmentInfo.createdAt
      )}`
    }
  }

  private fetchDetail() {
    controller.getClaimDetail(this.$route.params.id)
  }

  private getDate(date: string | undefined) {
    return date ? dayjs(date).format('DD MMM YYYY') : '-'
  }

  private getTime(date: string | undefined) {
    return date ? dayjs(date).format('HH:mm [WIB]') : '-'
  }

  private formatCurrency(input: number, prefix = 'Rp ') {
    return `${prefix}${Utils.currencyDigit(input)}`
  }

  private countDown(date: string | undefined) {
    const dateDiff = dayjs(date).add(2880, 'minutes').diff(dayjs(), 'minutes')

    return this.claimStatus.statusId === EnumOldClaimStatus.APPROVED
      ? 'Approved'
      : this.claimStatus.statusId === EnumOldClaimStatus.REJECTED
      ? 'Rejected'
      : dateDiff > 0
      ? `${dayjs(date).add(48, 'hours').toNow(true)} left for action`
      : `Exceed ${dayjs(date).add(dateDiff, 'minutes').fromNow(true)}`
  }

  private onImageClick(url: string) {
    window.open(url, '_blank')
  }

  private isHasAdjustment(): boolean {
    return (this.packageInfo.totalUnderpay && this.packageInfo.totalUnderpay > 0) || (this.packageInfo.totalOverpay && this.packageInfo.totalOverpay > 0) || false
  }

  private toogleShowDetail(): void {
    if (this.isHasAdjustment()) {
      this.isShowDetail = !this.isShowDetail
    } else {
      this.isShowDetail = false
    }
  }

  get isBudgetAmountShow(): boolean {
    return this.claimStatus.statusId === EnumNewClaimStatus.PAID || this.claimStatus.statusId === EnumOldClaimStatus.APPROVED
  }
}
